.container.--help {
    padding: 0 20px;

    .content {
        
        h1 {
            text-align: center;
            font-size: 32px;
            color: #62A781;
            font-weight: 600;
            margin-bottom: 20px;

            @media (max-width: 1400px) {
                font-size: 28px;
            }

            @media (max-width: 1100px) {
                font-size: 25px;
            }

            @media (max-width: 900px) {
                font-size: 22px;
            }
        }

        h2 {
            font-size: 28px;
            color: #6c6c6c;
            font-weight: 600;
            margin-top: 40px;
            margin-bottom: 20px;

            @media (max-width: 1400px) {
                font-size: 24px;
            }

            @media (max-width: 1100px) {
                font-size: 21px;
            }

            @media (max-width: 900px) {
                font-size: 18px;
            }
        }

        p {
            margin-bottom: 10px;
            line-height: normal;
        }

        .ant-list-item-meta {
            margin-block-end: 0;
            align-items: center;

            .ant-list-item-meta-title {
                margin-block-end: 0;

                a {
                    color: inherit !important;
                }
            }
        }
        
        ul {
            li {
                border-radius: 10px;
                transition: all .2s;
                cursor: pointer;

                &:hover {
                    background-color: #e9e9e957;
                }

                img {
                    max-width: 32px;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}