.container.--error {
    padding: 0 20px;

    .content {
        text-align: center;
        justify-content: center;

        .error {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            user-select: none;

            span {
                font-size: 40px;
                color: #62A781;
                margin-bottom: 10px;
            }
        }
    }
}