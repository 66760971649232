.container.--profile {
    padding: 0 20px;

    .ant-tabs-nav-wrap {
        min-width: 150px;
    }
    
    .content {
        
        h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .on_auth {

            .default {
                margin: auto;
                background-color: #62A781;
                color: #fff;
                font-weight: 700;
                border-radius: 14px;
                font-size: 16px;
                border: none;
                height: 40px;
                display: flex;
                align-items: center;
                width: fit-content;
            }
        }

        .profile_content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .profile_left {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 auto;
                width: 280px;

                .information {

                    .url_info {
                        margin-bottom: 10px;
                    }
                }

                .profile_card {
                    display: flex;
                    flex-direction: column;
                    margin: 0 auto;
                    width: 280px;
                    min-height: 350px;
                    height: fit-content;
                    background: #62A781;
                    position: relative;
                    border-radius: 14px;
                    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
        
                    .profile_avatar {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
    
                        > img {
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            border: 5px solid #F9F9F9;
                            display: flex;
                            margin: 20px auto 0 auto;
                        }
    
                        > .ant-skeleton {
                            margin: 20px auto 0 auto;
                        }
                    }
        
                    .card_content {
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        flex: 1;
        
                        .card_content_body {
                            text-align: center;
                            margin-bottom: 20px;
        
                            h1 {
                                text-transform: uppercase;
                                font-size: 22px;
                                color: #fff;
                            }
        
                            p {
                                font-size: 12px;
                                color: #fff;
                                margin-bottom: 5px;
                            }
                        }
        
                        .card_content_footer {
                            display: flex;
                            flex-direction: column;
                            margin-top: auto;
                            gap: 10px;
    
                            button {
                                font-weight: 700;
                                border-radius: 14px;
                                font-size: 12px;
                                border: none;
                                height: 40px;
                                width: 100%;
    
                                &.default {
                                    background-color: #fff;
                                    color: #62A781;
                                    fill: #62A781;
                                    
                                }
                    
                                &.exit {
                                    background-color: #db4949;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .profile_right {
                flex: 1;
                padding: 20px;
                width: 100%;
                border-radius: 14px;

                .organization_list {

                    .ant-list-item {

                        .orgname {

                        }

                        .ant-list-item-action {

                            .submit {
                                background-color: #62A781;
                                color: #fff;
                                border-radius: 14px;
                                font-size: 11px;
                                border: none;
                                height: 28px;
                            }
                        }
                    }
                }

                .default_form {

                    .button_left {
                        background-color: #48ab48;
                        color: #fff;
                        font-weight: 700;
                        border-radius: 14px;
                        font-size: 16px;
                        border: none;
                        height: 40px;
    
                        @media (max-width: 500px) {
                            width: 100%;
                        }
                    }
                }
            }

            .profile_button_edit {
                position: absolute;
                top: 10px;
                left: 10px;
            }

            .profile_button_exit {
                position: absolute;
                top: 10px;
                right: 10px;
            }

            @media (max-width: 900px) {
                flex-direction: column;

                > div {
                    width: 100% !important;
                }
            }

            @media (max-width: 1300px) {
                flex-direction: column-reverse;
            }
        }
    }
}

.modal_review {
    .r_item {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 32px;
        }

        .r_header {
            margin-bottom: 12px;

            .rate {
                margin-right: 5px;
                color: #62A781;
                font-weight: 700;
                font-size: 22px;

                @media (max-width: 1400px) {
                    font-size: 18px;
                }

                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }

            .anticon-star {
                margin-right: 12px;
                font-size: 20px;

                @media (max-width: 1400px) {
                    font-size: 18px;
                }

                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }

            .author {
                color: #525252;
                font-size: 22px;
                font-weight: 700;
                margin-right: 24px;

                @media (max-width: 1400px) {
                    font-size: 18px;
                }

                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }

            .date {
                color: #9B9B9B;
                font-size: 16px;

                @media (max-width: 1400px) {
                    font-size: 14px;
                }

                @media (max-width: 1100px) {
                    font-size: 12px;
                }
            }
        }

        .r_body {
            font-size: 18px;
            color: #525252;

            @media (max-width: 1400px) {
                font-size: 16px;
            }

            @media (max-width: 1100px) {
                font-size: 14px;
            }

            p {
                line-height: normal;
                
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        .r_footer {
            margin-top: 12px;
            display: flex;
            gap: 24px;
            font-size: 22px;

            @media (max-width: 1400px) {
                font-size: 18px;
            }

            @media (max-width: 1100px) {
                font-size: 16px;
            }

            div {
                cursor: pointer;
                color: #9B9B9B;
                transition: all .2s;
                display: flex;
                gap: 4px;
                align-items: center;

                svg {
                    fill: #9B9B9B;
                    transition: all .2s;
                }

                &:hover {
                    color: #62A781;
                    
                    svg {
                        fill: #62A781;
                    }
                }
            }
        }
    }
}