.container.--pays_list {
    padding: 0 20px;
    max-width: 1600px;
    margin-top: 42px;

    .top {
        h1 {
            text-align: center;
            font-size: 52px;
            color: #62A781;
            font-weight: 600;
            margin-bottom: 11px;

            @media (max-width: 1400px) {
                font-size: 45px;
            }

            @media (max-width: 1100px) {
                font-size: 35px;
            }

            @media (max-width: 900px) {
                font-size: 25px;
            }
        }
    }

    .content {
        display: flex;
        gap: 40px;
        border-radius: 30px;
        padding: 50px;
        
        @media (max-width: 1100px) {
            flex-direction: column;
            gap: 30px;
        }

        @media (max-width: 900px) {
            gap: 20px;
            padding: 40px;
        }

        @media (max-width: 800px) {
            gap: 10px;
            padding: 30px;
        }

        @media (max-width: 700px) {
            padding: 20px;
        }

        .mp_info_mobile {
            display: none;

            @media (max-width: 1100px) {
                display: flex;
            }

            .mp_info {
                img {
                    border-radius: 20px;
                }
            }
        }

        .left_form {
            max-width: calc(40% - 20px);
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 42px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }

            .mp_info {
                img {
                    border-radius: 20px;
                }

                @media (max-width: 1100px) {
                    display: none;
                }
            }

            form {
                padding: 32px;
                background: #F9F9F9;
                border-radius: 26px;

                .custom_input {
                    background: #FFF;
                }

                .ant-select-selector {
                    background: #FFF;
                }
            }
        }

        .right_content {
            max-width: calc(60% - 20px);
            width: 100%;

            @media (max-width: 1100px) {
                max-width: 100%;
            }

            a {
                display: flex;
                gap: 24px;
                width: 100%;
                flex-wrap: wrap;

                @media (max-width: 900px) {
                    gap: 20px;
                }
        
                @media (max-width: 800px) {
                    gap: 16px;
                }
        
                @media (max-width: 700px) {
                    gap: 14px;
                    flex-direction: column;
                }

                .left {
                    .spec_item_left {
                        background: #E2E4E3;
                        border-radius: 20px;
                        overflow: hidden;
                        width: 200px;
                        height: 160px;

                        img {
                            width: 200px;
                            height: 160px;
                            object-fit: cover;
                            object-position: top;

                            @media (max-width: 700px) {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        @media (max-width: 700px) {
                            width: 100%;
                            height: max(250px, 50vw);
                        }

                        &.no_photo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            svg {
                                color: #b5b5b5;
                                font-size: 70px;
                            }
                        }
                    }
                }

                .right {
                    width: calc(100% - 200px - 24px);
                    display: flex;
                    flex-direction: column;
        
                    @media (max-width: 700px) {
                        width: 100%;
                    }
                }
            }
        }

        .ant-list-pagination {
            margin-block-start: 10px;
            margin-block-end: 10px;
        }

        .ant-pagination {
            justify-content: center;

            .ant-pagination-jump-prev {
                display: none;
            }

            .ant-pagination-jump-next {
                display: none;
            }

            .ant-pagination-item {
                border: 1px solid #62A781 !important;
                min-width: 40px;

                @media (max-width: 900px) {
                    min-width: 30px;
                    margin-inline-end: 6px;
                }

                @media (max-width: 700px) {
                    margin-inline-end: 4px;
                }

                &.ant-pagination-item-active {
                    border: 1px solid #62A781 !important;
                }

                a {
                    justify-content: center;
                    font-size: 12px;
                }

                &:hover {
                    background: #92d0ae;
                    border: 1px solid #92d0ae !important;

                    a {
                        color: #FFF;
                    }
                }
            }

            .ant-pagination-prev {
                button {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='32' viewBox='0 0 20 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.836982 17.7647L15.5522 31.2918C15.7939 31.5162 16.0814 31.6943 16.3982 31.8159C16.7151 31.9374 17.0549 32 17.3981 32C17.7413 32 18.0811 31.9374 18.3979 31.8159C18.7147 31.6943 19.0023 31.5162 19.244 31.2918C19.7282 30.8432 20 30.2364 20 29.6039C20 28.9714 19.7282 28.3646 19.244 27.916L6.37468 15.9452L19.244 4.09403C19.7282 3.64545 20 3.03865 20 2.40614C20 1.77363 19.7282 1.16682 19.244 0.718246C19.0032 0.492026 18.7161 0.312056 18.3992 0.188764C18.0823 0.0654727 17.7421 0.0013148 17.3981 -2.27467e-07C17.0541 0.00131474 16.7138 0.0654726 16.397 0.188764C16.0801 0.312056 15.793 0.492025 15.5522 0.718246L0.836982 14.2453C0.573084 14.4695 0.362473 14.7416 0.218422 15.0445C0.0743703 15.3473 1.42723e-06 15.6744 1.39832e-06 16.005C1.36942e-06 16.3357 0.0743702 16.6627 0.218421 16.9656C0.362472 17.2684 0.573084 17.5405 0.836982 17.7647Z' fill='%2362A781'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: contain;

                    &::before {
                        display: none;
                    }
                }

                &:hover {
                    background-color: transparent;

                    button {
                        background-color: transparent;
                    }
                }
            }

            .ant-pagination-next {
                button {
                    transform: rotate(180deg);
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='32' viewBox='0 0 20 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.836982 17.7647L15.5522 31.2918C15.7939 31.5162 16.0814 31.6943 16.3982 31.8159C16.7151 31.9374 17.0549 32 17.3981 32C17.7413 32 18.0811 31.9374 18.3979 31.8159C18.7147 31.6943 19.0023 31.5162 19.244 31.2918C19.7282 30.8432 20 30.2364 20 29.6039C20 28.9714 19.7282 28.3646 19.244 27.916L6.37468 15.9452L19.244 4.09403C19.7282 3.64545 20 3.03865 20 2.40614C20 1.77363 19.7282 1.16682 19.244 0.718246C19.0032 0.492026 18.7161 0.312056 18.3992 0.188764C18.0823 0.0654727 17.7421 0.0013148 17.3981 -2.27467e-07C17.0541 0.00131474 16.7138 0.0654726 16.397 0.188764C16.0801 0.312056 15.793 0.492025 15.5522 0.718246L0.836982 14.2453C0.573084 14.4695 0.362473 14.7416 0.218422 15.0445C0.0743703 15.3473 1.42723e-06 15.6744 1.39832e-06 16.005C1.36942e-06 16.3357 0.0743702 16.6627 0.218421 16.9656C0.362472 17.2684 0.573084 17.5405 0.836982 17.7647Z' fill='%2362A781'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: contain;

                    &::before {
                        display: none;
                    }
                }

                &:hover {
                    background-color: transparent;
                    
                    button {
                        background-color: transparent;
                    }
                }
            }
               
        }

        .ant-list-item-meta {
            margin-block-end: 0;
            align-items: center;

            .ant-list-item-meta-title {
                margin-block-end: 0;

                a {
                    color: inherit !important;
                }
            }
        }
        .ant-list-item {
            background: linear-gradient(95.49deg, rgba(240, 240, 240, 0.4) -72.57%, rgba(240, 240, 240, 0) 102.76%), #FFFFFF;
            -webkit-box-shadow: 4px 4px 10px rgba(180, 180, 180, 0.2), 1.63548px 1.63548px 1.63548px rgba(180, 180, 180, 0.1);
            -moz-box-shadow: 4px 4px 10px rgba(180, 180, 180, 0.2), 1.63548px 1.63548px 1.63548px rgba(180, 180, 180, 0.1);
            box-shadow: 4px 4px 10px rgba(180, 180, 180, 0.2), 1.63548px 1.63548px 1.63548px rgba(180, 180, 180, 0.1);
            border-block-end: none;
            margin-bottom: 12px;
            border-radius: 20px;
            padding: 24px;

            &:last-child {
                border-block-end: none !important;
                margin-bottom: 0;
            }
    
            @media (max-width: 900px) {
                padding: 20px;
            }
    
            @media (max-width: 800px) {
                padding: 16px;
            }
    
            @media (max-width: 700px) {
                padding: 14px;
            }

            .org_name {
                display: flex;
                gap: 20px;
                font-weight: 700;
                font-size: 20px;
                line-height: 120%;
                color: #525252;
                width: 100%;
    
                @media (max-width: 1100px) {
                    font-size: 16px;
                }
    
                @media (max-width: 900px) {
                    font-size: 14px;
                    gap: 15px;
                }
    
                @media (max-width: 700px) {
                    font-weight: 500;
                    gap: 10px;
                }
    
                .name {
                    margin: auto 0;
                }

                .tabs_rate {
                    display: flex;

                    @media (max-width: 700px) {
                        display: none;
                    }
                }
            }
        }

        .org_bottom {
            margin-top: 8px;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .last {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #525252;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (max-width: 1100px) {
                    font-size: 14px;
                }

                @media (max-width: 900px) {
                    font-size: 12px;
                }
            }
        }

        .tabs_rate {
            display: none;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            height: fit-content;

            @media (max-width: 700px) {
                display: flex;
            }

            .ant-rate {
                display: flex;
                margin-left: 12px;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;

                    @media (max-width: 900px) {
                        width: 18px;
                        height: 18px;
                    }
            
                    @media (max-width: 800px) {
                        width: 16px;
                        height: 16px;
                    }
            
                    @media (max-width: 700px) {
                        width: 14px;
                        height: 14px;
                    }
                }

                .ant-rate-star {
                    margin-inline-end: 4px;
                }
            }

            .rate {
                color: #62A781;
                font-weight: 700;
                font-size: 16px;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;

                @media (max-width: 1100px) {
                    font-size: 14px;
                }
    
                @media (max-width: 900px) {
                    font-size: 12px;
                }
    
                @media (max-width: 700px) {
                    font-size: 10px;
                }
            }
        }

        .org_info {
            margin-top: auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 10px;

            @media (max-width: 700px) {
                margin-top: 15px;
            }

            .experience {
                white-space: pre-wrap;
                word-break: break-all;
                line-height: normal;
                font-size: 16px;
                color: #525252;

                @media (max-width: 1100px) {
                    font-size: 14px;
                }
    
                @media (max-width: 900px) {
                    font-size: 12px;
                }
    
                @media (max-width: 700px) {
                    font-size: 10px;
                }
            }

            .category {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                span {
                    color: #FFFFFF;
                    background: #91d0ae;
                    border-radius: 20px;
                    padding: 4px 16px;
                }

                @media (max-width: 1100px) {
                    font-size: 12px;
                }
    
                @media (max-width: 900px) {
                    font-size: 10px;
                }
    
                @media (max-width: 700px) {
                    font-size: 8px;
                }
            }

            .count {
                margin-left: auto;
                color: #9B9B9B;
                font-size: 16px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                }
    
                @media (max-width: 900px) {
                    font-size: 12px;
                }
    
                @media (max-width: 700px) {
                    font-size: 10px;
                }
            }
        }
    }
}