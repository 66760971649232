header {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 112px;
    transition: all .5s;
    z-index: 100;
    background-color: #fff;

    .container {
        padding: 0 20px;
        position: relative;

        .content {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 30px 0;

            a {
                .ant-btn-text {
                    display: flex;
                    align-items: center;
                    color: #9B9B9B;
                    font-size: 16px;
                    background: none !important;

                    &:hover {
                        color: #868686;
                    }
                }
            }

            .a {
                .ant-btn-primary {
                    background-color: #62A781;
                }

                &.search {
                    margin-left: auto;
                }
            }

            .icon__mp {
                padding: 4px 15px;
                transition: all .5s;
                color: #62A781;
                position: relative;
                font-size: 20px;
                font-weight: 700;

                span {
                    background: #62A781;
                    color: #FFF;
                    padding: 5px;
                }
                
                &:hover {
                    opacity: .9;
                    background: none;
                }

                @media (max-width: 700px) {
                    width: calc(100% - 60px - 60px);
                    left: 60px;
                    position: absolute;
                }

                svg {
                    width: 135px;
                    height: 17px;

                    @media (max-width: 700px) {
                        width: 100%;
                        height: 22px;
                    }
                }
            }

            .mobile_hidden {
                @media (max-width: 1250px) {
                    display: none;
                }
            }

            .mobile_visible {
                display: none;

                @media (max-width: 700px) {
                    display: flex;
                    gap: 4px;
                    margin-left: auto;

                    .ant-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 28px;
                        height: 28px;
                        min-width: 28px;

                        svg {
                            font-size: 14px;
                        }
                    }
                }
            }

            .menu {
                display: block;
                color: rgba(0, 0, 0, 0.88);
                background-color: transparent !important;
            }

            .search_block {
                position: relative;
                display: flex;
                max-width: 984px;
                width: 100%;

                .citys {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: calc(100% + 10px);
                    background: #F4F4F4;
                    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    padding: 20px 50px;
                    max-height: calc(100vh - 100px);
                    overflow: auto;

                    @media (max-width: 700px) {
                        display: none;
                    }

                    ul {
                        column-count: 4;

                        li {
                            cursor: pointer;
                            font-size: 14px;
                            color: #525252;
                            margin-bottom: 8px;

                            &.active {
                                color: #323232;
                                text-shadow: 0px 0px .35px rgba(0,0,0,1);
                            }

                            &:hover {
                                color: #424242;
                                text-shadow: 0px 0px .35px rgba(0,0,0,1);
                            }
                        }

                        @media (max-width: 1300px) {
                            column-count: 3;
                        }

                        @media (max-width: 1150px) {
                            column-count: 2;
                        }

                        @media (max-width: 1150px) {
                            column-count: 3;
                        }

                        @media (max-width: 900px) {
                            column-count: 2;
                        }
                    }
                }

                .search_input {
                    height: 44px;
                    max-width: 984px;
                    border-radius: 10px;
                    background: #F4F4F4;
                    color: #9B9B9B;
                    font-size: 16px;

                    .ant-input-affix-wrapper {
                        cursor: pointer !important;

                        > * {
                            cursor: pointer !important;
                        }
                    }
                    
                    @media (max-width: 1100px) {
                        max-width: 100%;
                    }
    
                    &:hover {
                        background: #ececec;
                    }
                    
                    &.ant-input-affix-wrapper-focused {
    
                    }
    
                    .ant-input-wrapper {
                        height: 100%;
                    
                        .ant-input-affix-wrapper {
                            height: 100%;
    
                            input {
                                color: #9B9B9B;
                                font-size: 16px;
                            }
                        }
    
                        .ant-input-group-addon {
                            position: relative;
                            border: none;
                            width: 200px;
                            max-width: 200px;
                            background-color: transparent;
                            &::before {
                                content: "";
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 10%;
                                background: #9B9B9B48;
                                width: 1px;
                                height: 80%;
                            }
    
                            .city {
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                gap: 2px;
                                height: 100%;
                                width: 100%;
                                padding: 0;
    
                                &:hover {
                                    background: initial;
                                }
            
                                .icon {
                                    width: 14px;
                                    height: 14px;
                                }
            
                                .text {
                                    flex: 1;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    margin-right: 10px;
                                    max-width: 130px;
                                    text-align: left;
                                    margin-left: 5px;
                                }
    
                                .down {
                                    margin-left: auto;
                                }
                            }
    
                            .search_city {
                                text-align: left;
                                height: 100%;
                                box-shadow: none !important;
                                border: 2px solid #62A781 !important;
                                border-radius: 0px 10px 10px 0px !important;
                                background: #F4F4F4;
                            }
                        }
    
                        .ant-input-prefix {
                            margin-left: 10px;
                            margin-right: 10px;
    
                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
    
                    &.activeCity {
                        .ant-input-group-addon {
                            padding: 0;
    
                            .ant-btn {
                                background-color: transparent !important;
                                border: none !important;
    
                                span {
                                    transition: all .2s;
    
                                    &:hover {
                                        color: #868686 !important;
                                    }
                                }
                            }
    
                            .ant-input {
                                background: transparent !important;
                            }
                        }
                    }
                }

                @media (max-width: 700px) {
                    display: none;
                }
            }

            .button_auth {
                margin-left: auto;

                .ant-btn-primary {
                    background-color: #62A781;
                    font-weight: 700;
                    border-radius: 14px;
                    padding: 12px 18px;
                    font-size: 16px;
                    height: auto;
                    color: #fff;
                }
            }

            .search_input_mobile {
                display: none;
                border-radius: 10px;
                background: #F4F4F4;
                position: absolute;
                left: 0;
                top: 24px;
                width: 100%;
                height: 44px;
                color: #9B9B9B;
                font-size: 16px;
                z-index: 2;

                input {
                    color: #9B9B9B;
                    font-size: 16px;
                }

                .ant-input-prefix {
                    margin-left: 10px;
                    margin-right: 10px;
                }

                @media (max-width: 700px) {
                    &.active {
                        display: flex;
                    }
                }

                .ant-btn {
                    background-color: transparent !important;
                    border: none !important;

                    span {
                        transition: all .2s;

                        &:hover {
                            color: #868686 !important;
                        }
                    }
                }
            }
        }
    }

    .menu_mobile {
        height: auto;
        overflow: auto;
        position: absolute;
        left: 0;
        top: 0;
        height: 200vh;
        width: 100vw;
        transition: .5s all;
        transform: translateX(-150vw);
        display: flex;

        .container {
            max-width: 400px;
            margin-left: 0;
            background: #fff;
            height: 100%;
            -webkit-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.2);

            .content {
                display: flex;
                flex-direction: column;
                gap: 20px;

                ul {
                    width: 100%;
                    border-inline-end: none;
                    color: rgba(0,0,0,.85);
                }

                .head {
                    position: relative;
                    display: flex;
                    align-items: start;
                    gap: 8px;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;

                    .head_container {
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        justify-content: left;
                        width: 100%;
                    }

                    .search_group {
                        background: #fff;
                        z-index: 1;
                        width: 100%;

                        .search_group_content {
                            height: 44px;
                            background: #F4F4F4;
                            border-radius: 10px;

                            .city {
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                gap: 2px;
                                height: 100%;
                                width: 100%;
                                padding: 0 11px;

                                &:hover {
                                    background: initial;
                                }
            
                                .icon {
                                    width: 14px;
                                    height: 14px;
                                }
            
                                .text {
                                    flex: 1;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    margin-right: 10px;
                                    max-width: 300px;
                                    text-align: left;
                                    margin-left: 5px;
                                }

                                .down {
                                    margin-left: auto;
                                }
                            }

                            .search_city {
                                text-align: left;
                                height: 100%;
                                box-shadow: none !important;
                                border: 2px solid #62A781 !important;
                                border-radius: 10px !important;

                                .ant-btn {
                                    background-color: transparent !important;
                                    border: none !important;
        
                                    span {
                                        transition: all .2s;
        
                                        &:hover {
                                            color: #868686 !important;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                background: #ececec;
                            }
                        }

                        &.active {
                            width: 100%;
                            height: 100vh;
                            left: 0;
                            right: unset;
                        }

                        .citys {
                            margin-top: 20px;
                            
                            ul {
                                overflow: auto;
                                max-height: calc(100vh - 30px - 30px - 44px - 20px - 20px);
                                
                                li {
                                    cursor: pointer;
                                    font-size: 14px;
                                    color: #525252;
                                    margin-bottom: 8px;
        
                                    &.active {
                                        color: #323232;
                                        text-shadow: 0px 0px .35px rgba(0,0,0,1);
                                    }
        
                                    &:hover {
                                        color: #424242;
                                        text-shadow: 0px 0px .35px rgba(0,0,0,1);
                                    }
                                }
                                
                                @media (max-width: 700px) {
                                    max-height: calc(100vh - 30px - 37px - 44px - 20px - 20px);
                                }
                            }
                        }
                    }
                }

                .ant-menu {
                    border-inline-end: none !important
                }

                .menu_mobile_list {
                    width: 100%;
                    color: rgba(0, 0, 0, 0.85);
                    max-height: calc(100vh - 30px - 37px - 44px - 20px - 20px);
                    overflow: auto;

                    li, a {
                        height: 40px;
                        line-height: 40px;
                        padding-inline: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-inline: 4px;
                        margin-block: 4px;
                        width: calc(100% - 8px);
                        display: block;
                        margin: 0;
                        white-space: nowrap;
                        cursor: pointer;
                        transition: border-color 0.3s,background 0.3s,padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        border-radius: 8px;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.06);
                            color: inherit;
                        }

                        span {

                        }

                        &.button_profile {
                            background-color: #62A781;
                            font-weight: 700;
                            border-radius: 14px;
                            font-size: 16px;
                            color: #ffffff;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }
                }
            } 
        }

        .mask-container {
            flex: 1;
        }
    }

    &.active {
        .menu_mobile {
            transform: translateX(0) !important;
        }
    }

    @media (max-width: 700px) {
        height: 92px;
    }
}

.modal_authorization {
    .header {
        h2 {
            font-size: 32px;
            color: #525252;
            font-weight: 700;

            @media (max-width: 1400px) {
                font-size: 28px;
            }

            @media (max-width: 1100px) {
                font-size: 24px;
            }

            @media (max-width: 900px) {
                font-size: 20px;
            }
        }

        h3 {
            font-size: 24px;
            color: #9B9B9B;
            font-weight: 700;

            @media (max-width: 1400px) {
                font-size: 20px;
            }

            @media (max-width: 1100px) {
                font-size: 18px;
            }

            @media (max-width: 900px) {
                font-size: 16px;
            }
        }
    }
    
    .body {

        .result {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            h3 {
                text-align: center;
                font-size: 24px;
                color: #9B9B9B;
                font-weight: 700;
                margin-bottom: 15px;

                @media (max-width: 1400px) {
                    font-size: 20px;
                }

                @media (max-width: 1100px) {
                    font-size: 18px;
                }

                @media (max-width: 900px) {
                    font-size: 16px;
                }
            }

            .text {
                color: #525252;
                font-weight: 700;
            }

            .password {
                align-items: center;
                padding: 4px 15px;
                display: inline-flex;
                background: #F4F4F4;
                border: none !important;
                border-radius: 14px;
                font-size: 18px;
                box-shadow: none !important;
            }
        }

        .form {
            display: flex;
            flex-direction: column;

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0px 1000px #F4F4F4 inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            .custom_input {
                background: #F4F4F4;
                border: none !important;
                border-radius: 14px;
                font-size: 18px;
                box-shadow: none !important;
                height: 44px;

                .ant-input {
                    font-size: 18px;
                    background-color: transparent;
                    height: 100%;
                }
            }

            .ant-form-item-explain-error {
                margin-left: 15px;
                margin-top: 5px;
                margin-bottom: 15px;
            }

            label {
                display: block;
                color: #525252;
                font-weight: 700;
                margin-bottom: 5px;
                margin-left: 15px;
            }

            .forgot_password {
                color: #424242;
                cursor: pointer;
                width: fit-content;
                margin-left: 15px;
            }

            .form_buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                width: 100%;
                margin-top: 20px;

                .submit {
                    background-color: #62A781;
                    color: #fff;
                    font-weight: 700;
                    border-radius: 14px;
                    font-size: 16px;
                    border: none;
                    height: 40px;
                    margin-left: auto;

                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }

                .button_left {
                    background-color: #48ab48;
                    color: #fff;
                    font-weight: 700;
                    border-radius: 14px;
                    font-size: 16px;
                    border: none;
                    height: 40px;

                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }

                @media (max-width: 500px) {
                    flex-direction: column-reverse;
                }
            }
        }
    }
    
    .footer {

    }
}