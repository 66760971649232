.container.--reports {
    padding: 0 20px;
    max-width: 1600px;

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;

        h1 {
            color: #62A781;
            text-align: center;
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 24px;

            @media (max-width: 1400px) {
                font-size: 22px;
            }

            @media (max-width: 1300px) {
                font-size: 20px;
            }

            @media (max-width: 1200px) {
                font-size: 18px;
            }

            @media (max-width: 1100px) {
                font-size: 32px;
            }

            @media (max-width: 700px) {
                font-size: 30px;
            }

            @media (max-width: 600px) {
                font-size: 28px;
            }
        }

        p {
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            color: #525252;
            margin-bottom: 32px;

            @media (max-width: 1400px) {
                font-size: 16px;
            }

            @media (max-width: 1300px) {
                font-size: 14px;
            }

            @media (max-width: 1200px) {
                font-size: 12px;
            }

            @media (max-width: 1100px) {
                font-size: 18px;
            }

            @media (max-width: 700px) {
                font-size: 16px;
            }
        }

        .links {
            display: flex;
            gap: 20px;
            justify-content: center;
            flex-wrap: wrap;

            a {
                height: fit-content;
                width: calc(50% - 10px);
                color: #FFF;
                transition: .1s all;
                padding: 25px;
                background: #62A781;
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
                border-radius: 25px;
                display: flex;
                gap: 20px;
        
                @media (max-width: 900px) {
                    padding: 20px;
                }

                @media (max-width: 800px) {
                    padding: 18px;
                }

                @media (max-width: 700px) {
                    padding: 16px;
                }

                @media (max-width: 600px) {
                    padding: 14px;
                }

                @media (max-width: 500px) {
                    padding: 12px;
                }

                svg {
                    margin: auto 0;
                    height: 100px;
                    width: 100px;
                    transition: .3s all;
                    filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.4));

                    @media (max-width: 1400px) {
                        height: 90px;
                        width: 90px;
                    }
        
                    @media (max-width: 1100px) {
                        height: 80px;
                        width: 80px;
                    }
        
                    @media (max-width: 900px) {
                        height: 70px;
                        width: 70px;
                    }
        
                    @media (max-width: 800px) {
                        height: 70px;
                        width: 70px;
                    }
        
                    @media (max-width: 700px) {
                        height: 60px;
                        width: 60px;
                    }
        
                    @media (max-width: 600px) {
                        height: 40px;
                        width: 40px;
                    }
        
                    @media (max-width: 500px) {
                        height: 30px;
                        width: 30px;
                    }
                }

                span {
                    margin: auto 0;
                    font-size: 30px;
                    font-weight: 700;
                    transition: .3s all;

                    @media (max-width: 1400px) {
                        font-size: 26px;
                    }
        
                    @media (max-width: 1100px) {
                        font-size: 24px;
                    }
        
                    @media (max-width: 900px) {
                        font-size: 20px;
                    }
        
                    @media (max-width: 800px) {
                        font-size: 16px;
                    }
        
                    @media (max-width: 700px) {
                        font-size: 14px;
                    }
        
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
        
                    @media (max-width: 500px) {
                        font-size: 10px;
                    }
                }

                &:hover {
                    svg {
                        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
                        color: #FFF;
                    }

                    span {
                        color: #FFF;
                        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
                    }
                }
            }
        }
    }
}